import Vue from 'vue';
import { VPopover, VTooltip } from 'v-tooltip';
import Icon from '@rr-component-library/icon/src/main';
import Clipboard from 'v-clipboard';
import smoothscroll from 'smoothscroll-polyfill';
import i18n from './i18n';
import router from './router';
import store from './store';
import App from './App.vue';
import '@/assets/scss/main.scss';

Vue.config.productionTip = false;
Vue.config.devtools = !!localStorage.getItem('vue-debug');
VTooltip.options.popover.defaultBaseClass = 'base-tooltip base-popover';
VTooltip.options.popover.defaultWrapperClass = 'popover-wrapper';

Vue.component('Icon', Icon);
Vue.component('Link', () => import('@rr-component-library/link/src/main'));
Vue.component('Button', () => import('@rr-component-library/button/src/main'));
Vue.component('Row', () => import('@rr-component-library/row/src/main'));
Vue.component('Col', () => import('@rr-component-library/col/src/main'));
Vue.component('Alert', () => import('@rr-component-library/alert/src/main'));
Vue.component('Loader', () => import('@rr-component-library/loader/src/main'));
Vue.component('Input', () => import('@rr-component-library/input/src/main'));
Vue.component('Dropdown', () => import('@/components/Dropdown/Dropdown.vue'));
Vue.component('v-popover', VPopover);
Vue.use(Clipboard);

Vue.prototype.$env = window.VueEnv || {
  Championships: 'https://champs.russiarunning.com',
  Clubs: 'https://clubs.russiarunning.com',
  RussiaRunning: 'https://russiarunning.com',
};
window.VueEnv = Vue.prototype.$env;

// eslint-disable-next-line no-underscore-dangle
Vue.prototype.$lang = window.__LANGUAGE__ || 'ru';

i18n.locale = Vue.prototype.$lang;
i18n.pluralizationRules.ru = (val) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return (val % 100 > 4 && val % 100 < 20) ? 2 : cases[(val % 10 < 5) ? val % 10 : 5];
};

Vue.prototype.$goBack = (defaultBackLink) => {
  let backLink = sessionStorage.getItem('previousPath');

  const resolved = backLink
    ? router.resolve(backLink)
    : null;

  const useDefault = !resolved || !resolved.route.name
    || router.currentRoute.meta?.backLevel < resolved.route.meta?.backLevel;

  if (useDefault) {
    backLink = defaultBackLink || '/';
  }

  if (typeof backLink === 'string' && backLink.indexOf('http') === 0) {
    window.location.href = backLink;
    return;
  }

  router.push(backLink);
};

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      sessionStorage.setItem('previousPath', from.fullPath);
    }
    next();
  },
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

let isTouch = false;
if ('ontouchstart' in document.documentElement) {
  isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';
smoothscroll.polyfill();
