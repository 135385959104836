import axios from 'axios';
import detectMobile from '@/assets/js/detect-mobile';
import api from './api';

const { CancelToken } = axios;
const cancelTokens = {};
const isMobile = detectMobile();

// eslint-disable-next-line no-underscore-dangle
const lang = window.__LANGUAGE__ || 'ru';
let serverUrl = api.defaults.baseURL;

export default {
  setServerUrl(url) {
    if (process.env.NODE_ENV !== 'development') {
      serverUrl = url || api.defaults.baseURL;
    } else {
      serverUrl = api.defaults.baseURL;
    }
  },
  getBroadcasts() {
    return api.post('/LiveServices/GetBroadcasts', {
      language: lang,
    });
  },
  getAllBroadcasts() {
    return api.post('/LiveServices/GetAllBroadcasts');
  },
  getWeather({ latitude, longitude }) {
    return api.post('/api/weather', {
      latitude,
      longitude,
    });
  },
  getResults({
    raceId,
    chronoId,
    highlightedParticipantOrTeamId,
    clubCode,
    categoryId,
    ageCategoryId,
    genderCategoryId,
    raceStartId,
    resultStatus,
    page,
    pageSize,
    members,
  }) {
    return api.post(`${serverUrl}/LiveServices/GetResults`, {
      raceId,
      chronoId,
      highlightedParticipantOrTeamId,
      clubCode,
      categoryId,
      ageCategoryId,
      genderCategoryId,
      raceStartId,
      resultStatus,
      page,
      pageSize,
      members,
      isMobile,
      showJson: true,
      Language: lang,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.cancelGetResults = c;
      })),
    });
  },
  cancelGetResults() {
    if (cancelTokens.cancelGetResults) {
      cancelTokens.cancelGetResults('canceled');
    }
  },
  searchResults(data) {
    return api.post(`${serverUrl}/api/live/find-results`, null, {
      params: data,
      cancelToken: new CancelToken(((c) => {
        cancelTokens.cancelSearchResults = c;
      })),
    });
  },
  cancelSearchResults() {
    if (cancelTokens.cancelSearchResults) {
      cancelTokens.cancelSearchResults('canceled');
    }
  },
  searchResultsInEvent(data) {
    return api.post(`${serverUrl}/LiveServices/SearchParticipant`, {
      ...data,
      Language: lang,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.cancelSearchResultsInEvent = c;
      })),
    });
  },
  cancelSearchResultsInEvent() {
    if (cancelTokens.cancelSearchResultsInEvent) {
      cancelTokens.cancelSearchResultsInEvent('canceled');
    }
  },
  getParticipantDetails({
    raceId,
    chronoId,
    startNumber,
  }) {
    return api.post(`${serverUrl}/LiveServices/GetDetails`, {
      raceId,
      chronoId,
      startNumber,
      language: lang,
    });
  },
  getEventInfo({ raceId, chronoId }) {
    return api.post(`${serverUrl}/LiveServices/GetEventInfo`, {
      raceId,
      chronoId,
    });
  },
  getLeaders({
    raceId,
    chronoId,
    leadersSize,
    leadersSizeByCategory,
    resultsCalculationMethod,
  }) {
    return api.post(`${serverUrl}/LiveServices/GetLeaderResults`, {
      raceId,
      chronoId,
      leadersSize,
      leadersSizeByCategory,
      resultsCalculationMethod,
      language: lang,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.cancelGetLeaders = c;
      })),
    });
  },
  cancelGetLeaders() {
    if (cancelTokens.cancelGetLeaders) {
      cancelTokens.cancelGetLeaders('canceled');
    }
  },
  getStatistics({ eventCode }) {
    return api.post(`${serverUrl}/LiveServices/GetStatistics`, {
      eventCode,
      language: lang,
    }, {
      cancelToken: new CancelToken(((c) => {
        cancelTokens.cancelGetStatistics = c;
      })),
    });
  },
  cancelGetStatistics() {
    if (cancelTokens.cancelGetStatistics) {
      cancelTokens.cancelGetStatistics('canceled');
    }
  },
};
