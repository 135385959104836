<template>
  <div class="admin-bar">
    <div class="admin-bar__container">
      <div class="admin-bar__col d-lg-none">
        <button
          class="admin-bar__action"
          @click="open"
        >
          <Icon name="menu"></Icon>
          <span>{{ $t('admin_bar.menu_btn') }}</span>
        </button>
      </div>
      <div
        ref="nav"
        class="admin-bar__nav admin-bar__col d-none d-lg-block"
      >
        <div
          ref="navContainer"
          class="admin-bar__nav-items"
          :class="{'transparent': !menuUpdated}"
        >
          <template v-for="(item, i) in menu">
            <div
              v-if="item.divider"
              class="admin-bar__divider"
              :key="i"
            ></div>
            <a
              v-else
              :key="item.text"
              class="admin-bar__action"
              :href="item.href"
            >
              <Icon :name="item.icon"></Icon>
              <span>{{ item.text }}</span>
            </a>
          </template>
          <button
            ref="more"
            class="admin-bar__action"
            v-show="menuHidden.length"
          >
            <span>{{ $t('admin_bar.more') }}</span>
            <Icon name="down"></Icon>
          </button>
          <Dropdown
            trigger="more"
            listeners="hover click"
            placement="bottom-end"
          >
            <template slot="items">
              <div
                class="base-dropdown__item"
                v-for="(menuItem, i) in menuHidden.filter((o) => !o.divider)"
                :key="i"
              >
                <a
                  class="base-dropdown__link"
                  :href="menuItem.href"
                >
                  <Icon
                    class="base-dropdown__icon"
                    :name="menuItem.icon"
                  ></Icon>
                  <span class="base-dropdown__text">
                    {{ menuItem.text }}
                  </span>
                </a>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="admin-bar__actions admin-bar__col">
        <a
          class="admin-bar__action"
          :href="`${$env.RussiaRunning}/Auth/LogOff`"
        >
          <Icon name="logout"></Icon>
          <span>{{ $t('admin_bar.sign_out') }}</span>
        </a>
      </div>
    </div>
    <HeaderMobileNav
      v-model="isOpened"
      :nav="mobileMenu"
      dark
    >
      <template v-slot:logo>
        <a :href="$env.RussiaRunning">
          <img :src="themeImages['logo-white']" alt="">
        </a>
      </template>
    </HeaderMobileNav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderMobileNav from '@/components/HeaderMobileNav/HeaderMobileNav.vue';

const isAdminOrJudgeOrChrono = !!window.userInfo && (
  window.userInfo.IsAdmin
  || window.userInfo.IsJudge
  || window.userInfo.IsChronometrist
);

export default {
  name: 'AdminBar',
  components: {
    HeaderMobileNav,
  },
  i18n: {
    messages: {
      ru: {
        admin_bar: {
          command_center: 'Командный центр',
          chrono: 'Хронометраж',
          diplomas: 'Дипломы',
          chip_check: 'Проверка чипов',
          organizers: 'ЛК Организатора',
          championships: 'ЛК Чемпионаты',
          clubs: 'ЛК Клубы',
          sign_out: 'Выйти',
          menu_btn: 'Админ панель',
          more: 'Ещё',
        },
      },
      en: {
        admin_bar: {
          command_center: 'Command Center',
          chrono: 'Chronometry',
          diplomas: 'Diplomas',
          chip_check: 'Chip Check',
          organizers: 'Organizers',
          championships: 'Championships',
          clubs: 'Clubs',
          sign_out: 'Sign Out',
          menu_btn: 'Admin Panel',
          more: 'More',
        },
      },
    },
  },
  data() {
    return {
      isOpened: false,
      menuHidden: [],
      menuUpdated: false,
      mobileMenu: [],
      menu: [
        {
          text: this.$t('admin_bar.command_center'),
          href: '/commandCenter',
          icon: 'team',
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          text: this.$t('admin_bar.chrono'),
          href: '/Chrono',
          icon: 'stopwatch',
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          text: this.$t('admin_bar.diplomas'),
          href: '/Chrono/PrintDocuments',
          icon: 'solution',
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          text: this.$t('admin_bar.chip_check'),
          href: '/Chrono/Chip',
          icon: 'apple-watch',
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          divider: true,
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          text: this.$t('admin_bar.organizers'),
          href: window.userInfo.AdminUrl,
          icon: 'user',
          hidden: !isAdminOrJudgeOrChrono,
        },
        {
          text: this.$t('admin_bar.championships'),
          href: `${this.$env.Championships}/Admin`,
          icon: 'trophy',
        },
        {
          text: this.$t('admin_bar.clubs'),
          href: `${this.$env.Clubs}/admin`,
          icon: 'user-groups',
        },
      ].filter((o) => !o.hidden),
    };
  },
  computed: {
    ...mapGetters('settings', ['themeImages']),
  },
  methods: {
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    updateNav() {
      if (window.innerWidth < 1024) {
        this.menuUpdated = true;
        return;
      }

      const { nav } = this.$refs;
      const container = this.$refs.navContainer;

      if (!container) {
        this.menuUpdated = true;
        return;
      }

      this.menuItemsWidth = this.menuItemsWidth || [];
      const availableSpace = nav.offsetWidth;
      const widths = this.menuItemsWidth;

      if (container.offsetWidth > availableSpace) {
        widths.push(container.offsetWidth);
        const link = this.menu.splice(this.menu.length - 1);
        this.menuHidden.unshift(link[0]);
      } else if (availableSpace > widths[widths.length - 1]) {
        const link = this.menuHidden.splice(0, 1);
        this.menu.push(link[0]);
        widths.pop();
      }

      this.$nextTick(() => {
        if (container.offsetWidth > availableSpace) {
          this.updateNav();
        }
      });
      this.menuUpdated = true;
    },
  },
  created() {
    this.mobileMenu = [...this.menu];
    document.body.classList.add('has-admin-bar');
  },
  mounted() {
    setTimeout(() => {
      this.updateNav();
      window.addEventListener('resize', this.updateNav);
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "./AdminBar";
</style>
