<template>
  <div
    class="feature-card block"
    :is="href ? 'a' : 'div'"
    :href="href"
    :target="target"
  >
    <div class="feature-card__item feature-card__img">
      <img :src="img" alt="" width="48" height="48">
    </div>
    <h3 class="feature-card__item feature-card__title">{{ title }}</h3>
    <div class="feature-card__item feature-card__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    img: String,
    title: String,
    href: String,
    target: String,
  },
};
</script>

<style lang="scss" scoped>
@import "./FeatureCard";
</style>
