<template>
  <div class="broadcast-intro">
    <div class="container">
      <div
        class="broadcast-intro__img"
        v-show="eventImage"
        :style="{backgroundImage: `url(${eventImage})`}"
      ></div>
      <div class="broadcast-intro__overlay"></div>
      <div class="broadcast-intro__content">
        <div class="broadcast-intro__item">
          <h1 class="broadcast-intro__title d-none d-md-block" v-html="$t('broadcast.title')"></h1>
          <h2 class="broadcast-intro__title d-md-none" v-html="$t('broadcast.title')"></h2>
        </div>
        <div class="broadcast-intro__item">
          <slot></slot>
        </div>
        <div class="broadcast-intro__item">
          <div class="broadcast-intro__info">
            <div class="broadcast-intro__info-row">
              <div class="broadcast-intro__info-item">
                <div class="text-with-icon text-nowrap">
                  <Icon class="icon" name="clock" size="m"></Icon>
                  <span>{{ currentTime }}</span>
                </div>
              </div>
              <div class="broadcast-intro__info-item" v-if="weather">
                <WeatherBadge
                  :weather="weather"
                  simple
                ></WeatherBadge>
              </div>
              <div class="broadcast-intro__info-item" v-if="location">
                <div class="text-with-icon text-nowrap">
                  <Icon class="icon" name="environment" size="m"></Icon>
                  <span v-html="locationClipped" :title="location"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeatherBadge from '@/components/WeatherBadge/WeatherBadge.vue';

export default {
  name: 'BroadcastIntro',
  components: {
    WeatherBadge,
  },
  props: {
    weather: Object,
    location: String,
    timezone: Number,
  },
  data() {
    return {
      time: new Date(),
      timeTimeout: null,
      eventImage: null,
    };
  },
  computed: {
    currentTime() {
      if (typeof this.timezone !== 'number') {
        return null;
      }
      const d = this.time;
      const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      const nd = new Date(utc + (3600000 * this.timezone));
      return nd.toLocaleString('ru', { hour: '2-digit', minute: '2-digit' });
    },
    locationClipped() {
      if (!this.location) {
        return '';
      }
      return this.location.length - 24 > 3
        ? `${this.location.slice(0, 24)}&#8230;`
        : this.location;
    },
  },
  methods: {
    updateTime() {
      this.time = new Date();
      this.timeTimeout = setTimeout(this.updateTime, 1000);
    },
    setImage(img) {
      this.eventImage = img;
    },
  },
  mounted() {
    this.updateTime();
  },
  beforeDestroy() {
    clearTimeout(this.timeTimeout);
  },
};
</script>

<style lang="scss" scoped>
@import "./BroadcastIntro";
</style>

<style lang="scss">
.broadcast-intro {

  .weather-badge {
    font: inherit;
    vertical-align: top;

    &__icon {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }
  }
}
</style>
