import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(locales) {
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const generalMessages = loadLocaleMessages(require.context(
  '@/locales',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i,
));

const localMessages = loadLocaleMessages(require.context(
  './locales',
  true,
  /[A-Za-z0-9-_,\s]+\.json$/i,
));

const messages = {};

Object.keys(generalMessages).forEach((key) => {
  messages[key] = localMessages[key]
    ? {
      ...generalMessages[key],
      ...localMessages[key],
    }
    : generalMessages[key];
});

export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages,
});
