<template>
  <div class="cookie-gdpr" v-if="isActive">
    <div class="cookie-gdpr__window">
      <div class="cookie-gdpr__text">
        {{ $t('cookie.text') }}
      </div>
      <div class="cookie-gdpr__actions">
        <div class="cookie-gdpr__actions-row">
          <div class="cookie-gdpr__action">
            <slot name="actions">
              <Button
                variant="primary"
                :text="$t('cookie.button')"
                mobile
                wide
                @click="hide"
              ></Button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  name: 'CookieGDPR',
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    hide() {
      const url = this.$env.RussiaRunning.split('://');
      const domain = url[1].replace(/\/+$/, '');
      Cookies.set('acceptedrrcookies', 'true', { expires: 60, domain });
      this.isActive = false;
    },
  },
  mounted() {
    this.isActive = Cookies.get('acceptedrrcookies') !== 'true';
  },
};
</script>

<style lang="scss" scoped>
@import "./CookieGDPR";
</style>
