import Vue from 'vue';
import Vuex from 'vuex';
import countriesRu from '@/files/countries_ru.json';
import countriesEn from '@/files/countries_en.json';

import settings from '@/store/modules/settings';
import i18n from '../i18n';
import broadcasts from './modules/broadcasts';
import follow from './modules/follow';

const countries = {
  ru: countriesRu,
  en: countriesEn,
};

Vue.use(Vuex);

let isMainLive = true;
if (process.env.NODE_ENV !== 'development') {
  const { host } = window.location;
  const subdomain = host.split('.')[1] ? host.split('.')[0] : false;
  isMainLive = !subdomain || !/^live\d+$/.test(subdomain);
}

export default new Vuex.Store({
  state: {
    isMainLive,
    disableNewFeatures: false,
    autoUpdateAvailable: process.env.NODE_ENV !== 'development',
    loadWeatherAvailable: process.env.NODE_ENV !== 'development',
    genders: {
      Male: 1,
      Female: 2,
    },
    resultStatuses: {
      Finish: 1,
      DSQ: 2,
      DNF: 3,
      DNS: 4,
      NC: 5,
      LAP: 6,
    },
  },
  mutations: {
    setDisableNewFeatures(state, value) {
      state.disableNewFeatures = value;
    },
  },
  getters: {
    disableNewFeatures: (state) => state.disableNewFeatures,
    gendersByName: (state) => state.genders,
    resultStatusesByName: (state) => state.resultStatuses,
    resultStatusesById: (state) => Object.keys(state.resultStatuses)
      .reduce((o, key) => ({
        ...o,
        [state.resultStatuses[key]]: key,
      }), {}),
    countryById: () => (id) => {
      const values = countries[i18n.locale] || countries.ru;
      return values.find((country) => country.id === id);
    },

    visibiltyFollowMemberAvailable(_, getters) {
      const hideFollowButton = ['pionercup'];
      const theme = getters['settings/theme'];

      return !hideFollowButton.includes(theme);
    },
  },
  modules: {
    broadcasts,
    follow,
    settings,
  },
});
