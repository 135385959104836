<template>
  <transition
    name="nav-transition"
    tag="div"
    mode="out-in"
  >
    <div
      class="rr-mobile-nav"
      :class="{
        'rr-mobile-nav_dark': dark,
        'rr-mobile-nav_opened': isOpened,
      }"
      v-show="isOpened"
    >
      <div
        class="rr-mobile-nav__overlay"
        @click="close"
      ></div>
      <div class="rr-mobile-nav__container">
        <div class="rr-mobile-nav__header">
          <slot name="logo">
            <div></div>
          </slot>
          <Button
            class="rr-mobile-nav__close"
            variant="clear"
            shape="square"
            icon-left="close"
            @click="close"
          ></Button>
        </div>
        <div class="rr-mobile-nav__body">
          <template
            v-for="(link, i) in nav"
          >
            <div
              v-if="link.divider"
              class="rr-mobile-nav__divider"
              :key="i"
            ></div>
            <NavigationItem
              v-else
              :key="link.text"
              v-bind="{
                ...link,
                icon: link.icon || 'caret-right',
                dark,
              }"
            ></NavigationItem>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NavigationItem from '@/components/NavigationItem/NavigationItem.vue';

export default {
  name: 'HeaderMobileNav',
  designVersion: '2.0.0',
  designLink: 'https://www.figma.com/file/MAH9taJUL2HzqvGofNpg5Z/Components?node-id=1256%3A67',
  components: {
    NavigationItem,
  },
  model: {
    prop: 'isOpened',
    event: 'change',
  },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
    isOpened: Boolean,
    dark: Boolean,
  },
  methods: {
    open() {
      this.$emit('change', true);
    },
    close() {
      this.$emit('change', false);
    },
  },
  watch: {
    isOpened: {
      handler(val) {
        document.body.style.overflow = val ? 'hidden' : '';
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./HeaderMobileNav";
</style>
