<template>
  <router-link
    :is="link.indexOf('http') === 0 ? 'a' : 'router-link'"
    class="broadcast-card"
    :to="link"
    :href="link"
  >
    <div class="broadcast-card__container">
      <div class="broadcast-card__item">
        <div class="broadcast-card__title">{{ title }}</div>
      </div>
      <div class="broadcast-card__item">
        <div class="broadcast-card__disciplines text-xs">
          <div
            class="broadcast-card__discipline"
            v-for="discipline in disciplinesWithIcons"
            :key="discipline.code"
          >
            <div class="text-with-icon text-nowrap">
              <Icon class="icon" :name="discipline.icon" size="xxs"></Icon>
              <span>{{ $t(`general.${[discipline.code]}`) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="broadcast-card__item broadcast-card__footer">
        <div class="broadcast-card__footer-item broadcast-card__location">
          <div class="broadcast-card__location-text text-with-icon text-nowrap text-sm">
            <Icon class="icon color-muted" name="environment" size="s"></Icon>
            <span>{{ location }}</span>
          </div>
        </div>
        <div
          class="broadcast-card__footer-item"
          v-if="weather">
          <WeatherBadge
            :weather="weather"
            size="sm"
          ></WeatherBadge>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { getDisciplineIcon } from '@/utils';
import WeatherBadge from '@/components/WeatherBadge/WeatherBadge.vue';

export default {
  name: 'BroadcastCard',
  components: {
    WeatherBadge,
  },
  props: {
    title: String,
    disciplines: Array,
    location: String,
    weather: Object,
    link: [Object, String],
  },
  computed: {
    disciplinesWithIcons() {
      // Add icon
      const disciplines = this.disciplines.map((o) => ({
        ...o,
        icon: getDisciplineIcon(o.code),
      }));
      // Sort by name
      disciplines.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name > b.name ? 1 : -1;
      });
      return disciplines;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./BroadcastCard";
</style>
