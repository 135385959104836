<template>
  <SearchResults
    v-model="value"
    ref="searchResults"
    class="broadcast-search"
    :class="{'broadcast-search_opened': isOpened}"
    :minLength="1"
    :placeholder="$t('participant_search.placeholder')"
    :isOpened="isOpened"
    :showRecentResults="true"
    :recentResultsStorageKey="`rr_live_ers_${chronoId}`"
    @search="searchResults"
    @change="cancelSearchResults"
    @select="findResult"
    @select:recent="recentResult = $event; close();"
    @close="close"
  >
    <template v-slot:field>
      <label class="broadcast-search__field">
        <Input
          ref="search"
          v-model.trim="value"
          :placeholder="$t('participant_search.placeholder')"
          :icon-right="!value ? 'search' : ''"
          type="search"
          no-border
          mobile
          clearable
          @focus="open"
          :loading="!!recentResult"
        ></Input>
      </label>
    </template>
    <template v-slot:resultLeftPart="{ result }">
      <div class="search-result__title" v-html="markQueryInString(`${capitalizeText(result.Name)} (№${result.StartNumber})`, value)"></div>
      <div class="search-result__subtitle" v-if="result.raceName">
        <div class="text-with-icon text-nowrap">
          <template v-if="result.raceDisciplineIcon">
          <Icon
            class="icon color-muted"
            :name="result.raceDisciplineIcon"
            size="s"
          ></Icon>&nbsp;&nbsp;</template><span>{{ result.raceName }}</span>
        </div>
      </div>
    </template>
    <template v-slot:tipPlaceholder>
      <div class="text-center" v-html="$t('participant_search.enter_tip')"></div>
    </template>
    <template v-slot:tipNotFound>
      <div v-show="!tooManyResults">
        <b v-html="$t('participant_search.not_found_title')"></b><br>
        <div class="text-sm mt-4" v-html="$t('participant_search.not_found_text')"></div>
      </div>
      <div v-show="tooManyResults">
        <b v-html="$t('participant_search.many_results_title')"></b><br>
        <div class="text-sm mt-4" v-html="$t('participant_search.many_results_text')"></div>
      </div>
    </template>
  </SearchResults>
</template>

<script>
import { mapGetters } from 'vuex';
import liveApi from '@/services/live-api';
import { capitalizeText } from '@/utils';
import { markQueryInString } from '@/assets/js/helpers';
import Input from '@rr-component-library/input/src/main';
import SearchResults from '../SearchResults/SearchResults.vue';

export default {
  name: 'BroadcastSearch',
  components: {
    Input,
    SearchResults,
  },
  props: {
    raceId: String,
    chronoId: String,
    races: {
      type: Array,
      default: () => [],
    },
    pageSize: Number,
  },
  data() {
    return {
      value: null,
      isOpened: false,
      notFound: false,
      tooManyResults: false,
      recentResult: null,
    };
  },
  computed: {
    ...mapGetters('broadcasts', ['allEvents']),
  },
  methods: {
    capitalizeText,
    markQueryInString,
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    getRaceIcon(raceId) {
      const race = this.races.find((o) => o.RaceId === raceId);
      return race ? race.disciplineIcon : null;
    },
    findResult(result) {
      this.close();
      if (!this.recentResult) {
        const data = {
          ParticipantOrTeamId: result.ParticipantOrTeamId,
          raceCode: result.raceCode,
        };
        this.$refs.searchResults.saveToRecent({
          id: result.ParticipantOrTeamId,
          title: `${capitalizeText(result.Name)} (№${result.StartNumber})`,
          value: `${capitalizeText(result.Name)} (№${result.StartNumber})`,
          data,
        });
      }
      this.$emit('findResult', {
        id: result.ParticipantOrTeamId,
        raceCode: result.raceCode,
        page: result.Page,
      });
    },
    searchResults(value) {
      // Prevent search, if recent result is on current page
      if (this.recentResult
        && Object.keys(this.recentResult.data).length
        && document.querySelector(`[data-id="${this.recentResult.id}"]`)
      ) {
        this.findResult(this.recentResult.data);
        this.$refs.searchResults.reset();
        this.recentResult = null;
        return;
      }

      // If string contains numbers, search by the last number,
      // not the whole string
      const numberMatches = value.match(/\d+/g) || [];
      const findString = numberMatches.length
        ? numberMatches.reverse()[0]
        : value;

      const formData = {
        chronoId: this.chronoId,
        findString,
        pageSize: this.pageSize,
      };

      liveApi.searchResults(formData)
        .then((response) => {
          const results = (response.data || [])
            // Exclude inactive races
            .filter((o) => this.races.some((r) => o.raceId === r.RaceId))
            // Add discipline code
            .map((o) => ({ ...o, raceDisciplineIcon: this.getRaceIcon(o.raceId) }));

          // Move results for current race to the top
          results.sort((a, b) => {
            if (a.raceId === this.raceId && b.raceId === this.raceId) {
              return 0;
            }
            if (a.raceId === this.raceId) {
              return -1;
            }
            return 1;
          });

          // Change default behaviour, if the recent result was selected
          if (this.recentResult) {
            this.close();
            const recentResultData = results.find((o) => o.ParticipantOrTeamId === this.recentResult.id);
            if (recentResultData) {
              this.findResult(recentResultData);
              this.$refs.searchResults.reset();
            }
            this.$refs.searchResults.setResults(value, null);
          } else {
            this.$refs.searchResults.setResults(value, results.slice(0, 5));
          }

          this.notFound = !results.length;

          if (!results.length) {
            this.open();
          }
        })
        .catch((error) => {
          if (error.message !== 'canceled') {
            this.notFound = false;
            this.tooManyResults = false;
            this.$refs.searchResults.setResults(value, null);
            this.open();
          }
        })
        .finally(() => {
          this.recentResult = null;
        });
    },
    cancelSearchResults() {
      liveApi.cancelSearchResults();
    },
    clearOldRecentSearch() {
      const allChronoIds = Object.keys(this.allEvents || {})
        .map((key) => this.allEvents[key].chronoId);

      // Clear inactive events
      (Object.keys(localStorage) || [])
        .filter((key) => key.indexOf('rr_live_ers_') === 0)
        .forEach((key) => {
          const chronoId = key.split('rr_live_ers_')[1];
          if (allChronoIds.indexOf(chronoId) === -1) {
            localStorage.removeItem(key);
          }
        });

      // Clear inactive races
      const recentResults = (JSON.parse(localStorage.getItem(`rr_live_ers_${this.chronoId}`)) || [])
        .filter((o) => this.races.some((r) => r.RaceCode === o.data.raceCode));
      this.$refs.searchResults.setRecentResults(recentResults);
    },
  },
  beforeDestroy() {
    this.close();
  },
  mounted() {
    const input = this.$refs.search.$el.querySelector('input');
    input.addEventListener('focus', (e) => {
      this.$refs.search.$emit('focus', e);
    });

    this.clearOldRecentSearch();
  },
};
</script>

<style lang="scss" scoped>
@import "./BroadcastSearch";
</style>
