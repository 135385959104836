/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import Vue from 'vue';
import liveApi from '@/services/live-api';
import eventBus from '@/eventBus';

const state = {
  events: {},
  allEvents: {},
  broadcastsInfo: {},
  eventsWeather: {},
  updateEventTimeout: null,
};

const getters = {
  events: (state) => state.events,
  allEvents: (state) => state.allEvents,
  broadcastsInfo: (state) => state.broadcastsInfo,
  eventsWeather: (state) => state.eventsWeather,
};

function groupBroadcasts(broadcasts) {
  return broadcasts.reduce((events, item) => {
    const eventCode = item.EventCode;
    let event = events[eventCode];

    if (!event) {
      event = {
        code: eventCode,
        name: item.EventName,
        id: item.EventId,
        chronoId: item.ChronometrageId,
        location: item.Place,
        timezone: item.TimeZoneOffset,
        disciplineCode: null,
        disciplineCodes: [],
        disciplines: [],
        headerBanner: item.EventHeaderBanner,
      };
    }

    if (event.disciplineCodes.indexOf(item.DisciplineCode) === -1) {
      event.disciplineCodes.push(item.DisciplineCode);
      event.disciplines.push({
        name: item.DisciplineName || item.DisciplineCode,
        code: item.DisciplineCode,
      });
    }

    [event.disciplineCode] = event.disciplineCodes;

    event.broadcasts = event.broadcasts || [];
    event.broadcasts.push(item);
    event.serverUrl = event.broadcasts[0].ServerUrl;
    /* eslint-disable no-param-reassign */
    events[eventCode] = event;
    /* eslint-disable no-param-reassign */
    return events;
  }, {});
}

function mapResult(result) {
  const r = result;

  // DSQ, DNS
  if (r.RaceResultStatus === 2
    || r.RaceResultStatus === 4
  ) {
    r.Stages = r.Stages.map((s) => ({
      ...s,
      Result: null,
      SubStages: s.SubStages
        ? s.SubStages.map((ss) => ({
          ...ss,
          Result: null,
        }))
        : null,
    }));
  }

  // DSQ, DNS, DNF
  if (r.RaceResultStatus === 2
    || r.RaceResultStatus === 4
    || (r.RaceResultStatus === 3 && r.ResultStatusIsForced)
  ) {
    r.TotalCalculatedAbsoluteTime = null;
    r.TotalCalculatedIndividualTime = null;
    r.TotalLeaderIndividualResultsDelay = null;
    r.TotalLeaderAbsoluteResultsDelay = null;
    r.TotalIndividualResultsDelay = null;
    r.TotalAbsoluteResultsDelay = null;
    r.TimeOffset = 0;
  }

  const stages = r.Stages || [];
  const isComplex = stages.some((s) => s.Stage.RaceStageType === 2);
  const substages = !isComplex
    ? stages
    : stages.reduce((arr, s) => arr.concat(s.SubStages || []), []);

  const lastFinishedStage = stages
    .slice().reverse()
    .find((s) => s.Result);

  let lastFinishedSubstageIndex = 0;
  substages
    .slice().reverse()
    .find((o, i) => {
      if (!o.Result) {
        lastFinishedSubstageIndex = i + 1;
      }
      return !!o.Result;
    });

  const distanceProgressPercent = substages.length
    && ((substages.length - lastFinishedSubstageIndex) / substages.length) * 100;

  return {
    ...r,
    getters: {
      lastFinishedStageId: lastFinishedStage?.Stage?.Id,
      distanceProgressPercent,
    },
  };
}

const mutations = {
  setBroadcastInfo(state, {
    raceId,
    data,
  }) {
    if (data) {
      Vue.set(state.broadcastsInfo, [raceId], data);
    }
  },
  setEvents(state, broadcasts) {
    const broadcastsToShow = broadcasts.filter((b) => b.ShowBannerAtHomePage);
    state.events = groupBroadcasts(broadcastsToShow);
    state.allEvents = groupBroadcasts(broadcasts);
  },
  setWeather(state, { eventCode, data }) {
    Vue.set(state.eventsWeather, [eventCode], data);
  },
  setUpdateEventTimeout(state, fn) {
    state.updateEventTimeout = setTimeout(fn, 3000);
  },
  clearUpdateEventTimeout() {
    clearTimeout(state.updateEventTimeout);
  },
};

const actions = {
  updateServerUrl({ getters }, { eventCode, chronoId }) {
    let serverUrl = null;
    if (eventCode) {
      serverUrl = getters.allEvents[eventCode]?.serverUrl;
    }
    if (chronoId) {
      Object.keys(getters.allEvents).find((key) => {
        const broadcast = getters.allEvents[key].broadcasts
          ?.find((b) => b.ChronometrageId === chronoId);
        serverUrl = broadcast?.ServerUrl;
        return broadcast;
      });
    }
    liveApi.setServerUrl(serverUrl);
  },
  updateEvents(args, isDirectLink) {
    const {
      state,
      commit,
      dispatch,
      rootState,
    } = args;

    clearTimeout(state.updateEventTimeout);

    const method = isDirectLink ? 'getAllBroadcasts' : 'getBroadcasts';

    return liveApi[method]()
      .then((response) => {
        let broadcasts = (response.data.Broadcasts || []);

        // All broadcasts are available only on MainLive and their own ServerUrl
        if (!rootState.isMainLive) {
          broadcasts = broadcasts.filter((b) => b.ServerUrl === window.location.origin);
        }

        // Hidden broadcasts are available only on their own ServerUrl
        broadcasts = broadcasts.filter((b) => b.ShowBannerAtHomePage || b.ServerUrl === window.location.origin);

        commit('setEvents', broadcasts);

        broadcasts.forEach((o) => {
          /*
          временно отлючил запрос погоды
          dispatch('loadWeather', {
            eventCode: o.EventCode,
            latitude: o.Latitude,
            longitude: o.Longitude,
          });
          */
          dispatch('follow/updateRaceFollowList', {
            eventCode: o.EventCode,
            raceId: o.RaceId,
          }, {
            root: true,
          });
        });
      })
      .finally(() => {
        if (rootState.autoUpdateAvailable) {
          commit('setUpdateEventTimeout', actions.updateEvents.bind(this, args, isDirectLink));
        }
        eventBus.$emit('eventsUpdated');
      });
  },
  loadWeather({ state, commit, rootState }, {
    eventCode,
    latitude,
    longitude,
  }) {
    if (!rootState.loadWeatherAvailable) {
      return;
    }
    if (!latitude || !longitude || state.eventsWeather[eventCode] !== undefined) {
      return;
    }
    Vue.set(state.eventsWeather, [eventCode], null);
    liveApi.getWeather({
      latitude,
      longitude,
    })
      .then((response) => {
        const { data } = response;
        if (data.s) {
          // data.v = Math.round(data.t - 273);
          commit('setWeather', {
            eventCode,
            data,
          });
        }
      });
  },
  loadBroadcastInfo({ commit, dispatch }, {
    chronoId,
    raceId,
  }) {
    return liveApi.getEventInfo({
      chronoId,
      raceId,
    })
      .then((response) => {
        const { data } = response;
        commit('setBroadcastInfo', {
          raceId,
          data,
        });
        dispatch('loadWeather', {
          eventCode: data.EventCode,
          latitude: data.LocationCoords?.Latitude,
          longitude: data.LocationCoords?.Longitude,
        });
      });
  },
  // eslint-disable-next-line no-empty-pattern
  async getResults({ getters, commit }, payload) {
    const response = await liveApi.getResults(payload);
    if (response.status === 200 && !response.data?.Error) {
      const { data } = response;
      const json = data && data.ResultsList.ItemsJson;
      if (!json) {
        return null;
      }
      const disableNewFeatures = !!data.DisableNewLiveFunctional;

      let broadcastConfig = null;
      Object.keys(getters.allEvents).find((eventCode) => {
        broadcastConfig = getters.allEvents[eventCode].broadcasts
          ?.find((b) => b.RaceId === payload.raceId);
        return broadcastConfig;
      });
      const hasCategories = broadcastConfig?.Categories?.length > 0;

      commit('setDisableNewFeatures', disableNewFeatures, { root: true });
      return {
        results: (json.Results || []).map(mapResult),
        stages: json.Stages || [],
        statistics: data.Statistics || {},
        config: {
          ageCalcPoint: data.AgeCalculationPoint,
          roundingRule: json.RoundingRule,
          calcMethod: json.ResultCalculationMethod,
          calcMethodByCategory: json.ResultCalculationMethodByCategory,
          hasRaceStarts: broadcastConfig?.HasRaceStarts,
          showCategory: json.ShowCategory && hasCategories,
          showCity: json.ShowCity,
          showClub: json.ShowClub,
          showCountry: json.ShowCountry,
          showHighlighted: json.ShowHighlighted,
          isRelay: json.IsRelay,
          isMono: !json.IsComplexDiscipline,
          isComplex: json.IsComplexDiscipline || (json.Stages || []).some((o) => o.RaceStages),
          disableNewFeatures,
          startTime: data.Start,
          startDate: data.StartDate,
        },
        highlightedId: data.HighlightedParticipantOrTeamId,
        totalItems: json.ResultsCount,
      };
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
