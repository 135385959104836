import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index/Index.vue';

Vue.use(VueRouter);

const availableModes = [
  'fs', // fullscreen
];

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        name: 'Broadcast',
        path: '',
        alias: 'live',
        component: () => import(/* webpackChunkName: 'live-participants' */ '../views/Participants/Participants.vue'),
        meta: {
          backLevel: 1,
          directName: 'Broadcast_direct',
        },
      },
      {
        name: 'Leaders',
        path: 'live/leaders',
        component: () => import(/* webpackChunkName: 'live-leaders' */ '../views/Leaders/Leaders.vue'),
        meta: {
          backLevel: 1,
          directName: 'Leaders_direct',
        },
      },
      {
        name: 'Statistics',
        path: 'live/statistics',
        component: () => import(/* webpackChunkName: 'live-statistics' */ '../views/Statistics/Statistics.vue'),
        meta: {
          backLevel: 1,
          directName: 'Statistics_direct',
        },
      },
    ],
  },
  {
    path: '/live/participant/:startNumber',
    name: 'Participant',
    component: () => import(/* webpackChunkName: 'live-participant' */ '../views/Participant/Participant.vue'),
    meta: {
      backLevel: 3,
      directName: 'Participant_direct',
    },
  },
  {
    path: '/live/participant_direct/:startNumber',
    name: 'Participant_direct',
    component: () => import(/* webpackChunkName: 'live-participant' */ '../views/Participant/Participant.vue'),
    meta: {
      backLevel: 3,
      isDirectLink: true,
    },
  },
  {
    path: '/live/liveFollow/:eventCode',
    name: 'Follow',
    component: () => import(/* webpackChunkName: 'live-follow' */ '../views/Follow/Follow.vue'),
    meta: {
      backLevel: 2,
      directName: 'Follow_direct',
    },
  },
  {
    path: '/live/liveFollow_direct/:eventCode',
    name: 'Follow_direct',
    component: () => import(/* webpackChunkName: 'live-follow' */ '../views/Follow/Follow.vue'),
    meta: {
      backLevel: 2,
      isDirectLink: true,
    },
  },
  {
    path: '/live/:eventCode/:raceCode?',
    component: Index,
    children: [
      {
        name: 'Broadcast_direct',
        path: '',
        component: () => import(/* webpackChunkName: 'live-participants' */ '../views/Participants/Participants.vue'),
        meta: {
          backLevel: 1,
          isDirectLink: true,
        },
      },
      {
        name: 'Leaders_direct',
        path: '/live/leaders/:eventCode/:raceCode?',
        component: () => import(/* webpackChunkName: 'live-leaders' */ '../views/Leaders/Leaders.vue'),
        meta: {
          backLevel: 1,
          isDirectLink: true,
        },
      },
      {
        name: 'Statistics_direct',
        path: '/live/statistics/:eventCode/:raceCode?',
        component: () => import(/* webpackChunkName: 'live-statistics' */ '../views/Statistics/Statistics.vue'),
        meta: {
          backLevel: 1,
          isDirectLink: true,
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

let mode = window.location.pathname.split('/')[1];
if (availableModes.indexOf(mode) === -1) {
  mode = '';
}
Vue.prototype.$isFullscreen = mode === 'fs';

const router = new VueRouter({
  mode: 'history',
  base: `/${mode}`,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name && to.path === from.path) {
      return null;
    }
    return savedPosition || { x: 0, y: 0 };
  },
});

export default router;
