<template>
  <div
    class="navigation-item"
    :class="{
      'navigation-item_dark': dark,
    }"
  >
    <a
      class="navigation-item__link"
      :is="isOpenable ? 'div'
        : href ? 'a'
        : to ? 'router-link'
        : 'div'"
      :href="href"
      :to="to"
      @click="click"
    >
      <Icon
        v-if="icon"
        class="navigation-item__icon"
        :name="icon"
      ></Icon>
      <div
        class="navigation-item__text"
        v-html="text"
      ></div>
      <icon
        v-if="isOpenable"
        class="navigation-item__caret"
        :name="isOpened ? 'up' : 'down'"
      ></icon>
    </a>
    <div
      v-if="isOpenable"
      v-show="isOpened"
      class="navigation-item__subitems"
    >
      <a
        v-for="item in items"
        :key="item.text"
        class="navigation-item__subitem"
        :is="href ? 'a' : to ? 'router-link' : 'div'"
        :href="href"
        :to="to"
        v-html="item.text"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationItem',
  designVersion: '2.0.0',
  designLink: 'https://www.figma.com/file/MAH9taJUL2HzqvGofNpg5Z/Components?node-id=1256%3A67',
  props: {
    text: String,
    icon: String,
    href: String,
    to: [Object, String],
    items: Array,
    dark: Boolean,
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    isOpenable() {
      return this.items && this.items.length;
    },
  },
  methods: {
    click(e) {
      if (this.isOpenable) {
        this.isOpened = !this.isOpened;
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./NavigationItem";
</style>
