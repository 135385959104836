<template>
  <header
    class="rr-header"
    :class="{'rr-header_scrolled': isScrolled}"
  >
    <div class="rr-header__wrap">
      <div
        class="rr-header__container"
        :class="{'rr-header__container_md': !isWide}"
      >
        <div class="rr-header__col mr-0">
          <div class="ml-8 d-md-none"></div>
          <div class="rr-header__col-item d-none d-lg-block pr-8">
            <a
              v-if="theme === 'rr'"
              :href="mainPageLink"
              class="rr-header__logo mr-0"
            >
            <span class="rr-header-logo">
              <img :src="themeImages['logo']" alt="" class="rr-header-logo__symbol">
            </span>
            </a>
            <a
              v-else
              :href="mainPageLink"
              class="rr-header__logo rr-header__logo_simple mr-0"
            >
              <img :src="themeImages['logo']" alt="">
            </a>
          </div>
          <div class="rr-header__col-item">
            <slot name="name"></slot>
          </div>
        </div>
        <div class="rr-header__col rr-header__col_center d-lg-none">
          <a
            v-if="theme === 'rr'"
            :href="mainPageLink"
            class="rr-header__logo mr-0"
          >
            <span class="rr-header-logo">
              <img :src="themeImages['logo']" alt="" class="rr-header-logo__symbol">
            </span>
          </a>
          <a
            v-else
            :href="mainPageLink"
            class="rr-header__logo rr-header__logo_simple mr-0"
          >
            <img :src="themeImages['logo']" alt="">
          </a>
        </div>
        <div class="rr-header__col rr-header__col_right">
          <!-- Lang switch : BEGIN -->
          <div class="rr-header__col-item" v-if="activeLang && languages.length > 1">
            <Button
              ref="lang"
              variant="clear"
              shape="square"
              icon-left="global"
              icon-size="xl"
            >
            </Button>
            <Dropdown
              trigger="lang"
              listeners="hover click"
              placement="bottom-end"
              hide-overlay
              auto-width
            >
              <template slot="items">
                <div
                  class="base-dropdown__item"
                  v-for="item in languages"
                  :key="item.id"
                  v-show="item.id !== $lang"
                >
                  <a
                    class="base-dropdown__link"
                    @click.prevent="changeLang(item.id)"
                  >
                    <span class="base-dropdown__icon flag-img">
                      <img
                        :src="require(`@/assets/img/langs/${item.id}.jpg`)"
                        :alt="item.id"
                      >
                    </span>
                    <span class="base-dropdown__text">{{ item.text }}</span>
                  </a>
                </div>
              </template>
            </Dropdown>
          </div>
          <!-- Lang switch : END -->
          <div class="rr-header__col-item d-none d-xl-block">
            <Link
              :href="mainPageLink"
              style="vertical-align: middle"
              icon-left="externa-link"
              size="sm"
              variant="secondary"
              :text="`${$t('general.backTo')} ${themeName}`"
            ></Link>
          </div>
          <div class="rr-header__col-item d-xl-none">
            <Button
              :href="mainPageLink"
              class="color-muted"
              icon-left="externa-link"
              variant="clear"
              shape="square"
              icon-size="xl"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import ru from './locales/ru.json';
import en from './locales/en.json';
import uz from './locales/uz.json';

export default {
  name: 'HeaderExternal',
  i18n: {
    messages: {
      ru,
      en,
      uz,
    },
  },
  props: {
    activeMenu: String,
    isWide: Boolean,
    hideLang: Boolean,
    title: String,
    navType: String,
  },
  data() {
    return {
      profileNav: [
        {
          name: this.$t('header.profile'),
          icon: 'user',
          link: `${this.$env.RussiaRunning}/Account`,
        },
        {
          name: this.$t('header.documents'),
          icon: 'solution',
          link: `${this.$env.RussiaRunning}/Account/admissions`,
        },
        {
          name: this.$t('header.registrations'),
          icon: 'badge',
          link: `${this.$env.RussiaRunning}/Account/RegistrationList`,
        },
        {
          name: this.$t('header.orders'),
          icon: 'shopping-bag',
          link: `${this.$env.RussiaRunning}/Account/OrderList`,
        },
        {
          name: this.$t('header.marks'),
          icon: 'five-of-five',
          link: `${this.$env.RussiaRunning}/Account/MyMarks`,
        },
        {
          name: this.$t('header.my_championships'),
          icon: 'trophy',
          link: `${this.$env.RussiaRunning}/Account/MyChampionships`,
        },
        {
          name: this.$t('header.teams'),
          icon: 'team',
          link: `${this.$env.RussiaRunning}/Account/MyTeams`,
        },
        {
          name: this.$t('header.my_clubs'),
          icon: 'user-groups',
          link: `${this.$env.RussiaRunning}/MyAccount/MyClubs`,
        },
        {
          name: this.$t('header.trainings'),
          icon: 'time-limit',
          link: `${this.$env.RussiaRunning}/MyAccount/Training`,
        },
        {
          isDivider: true,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: this.$t('header.organizers'),
          icon: 'caret-right',
          link: window.userInfo && window.userInfo.AdminUrl,
          hidden: !window.userInfo || (!window.userInfo.IsAdmin && !window.userInfo.IsBeneficiary),
        },
        {
          name: this.$t('header.bills'),
          icon: 'caret-right',
          link: `${this.$env.RussiaRunning}/Account/Bills`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
        {
          name: this.$t('header.cache'),
          icon: 'caret-right',
          link: `${this.$env.RussiaRunning}/CacheAdmin`,
          hidden: !window.userInfo || !window.userInfo.IsAdmin,
        },
      ],
      isScrolled: false,
      isAuthorized: true,
      user: null,
      live: false,
    };
  },
  computed: {
    ...mapGetters('settings', [
      'theme',
      'themeImages',
      'themeName',
      'themeSettings',
    ]),
    mainPageLink() {
      return this.theme === 'pionercup' ? this.$env.Main : this.$env.RussiaRunning;
    },
    languages() {
      return [
        { id: 'ru', text: 'Русский' },
        { id: 'en', text: 'English' },
        { id: 'uz', text: 'O\'zbek' },
      ].filter((o) => (this.themeSettings.languages || []).indexOf(o.id) !== -1);
    },
    activeLang() {
      return this.languages.find((o) => (o.id === this.$lang)) || {};
    },
  },
  methods: {
    toggleHeader() {
      this.isScrolled = window.pageYOffset > 10;
    },
    changeLang(lang) {
      setCulture(lang);
    },
  },
  mounted() {
    document.removeEventListener('scroll', this.toggleHeader);
    document.addEventListener('scroll', this.toggleHeader);
  },
  created() {
    this.user = window.userInfo;
    this.isAuthorized = !!this.user;
  },
};
</script>

<style lang="scss" scoped>
@import "./Header";

.rr-header__wrap {

  @media (min-width: breakpoint(lg)) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>

<style lang="scss">
.rr-header-offset {
  flex: 0 0 auto;
  height: 56px;

  @media (min-width: breakpoint(md)) {
    height: 64px;
  }
}
</style>
