<template>
  <main class="layout__main">
    <template v-if="!$isFullscreen">
      <Header
        isWide
      >
        <template v-slot:name>
          <img src="@/assets/img/live-logo.svg" alt="">
        </template>
      </Header>
      <div class="rr-header-offset"></div>
    </template>
    <section class="section_pt section_pb">
      <div class="container">
        <Row justify="center">
          <Col :size="{ lg: '20' }">
            <!-- Broadcasts : BEGIN -->
            <div class="section__group section__group_lg">
              <h1 class="h1_mb">{{ $t('live.title') }}</h1>
              <Loader v-show="eventsLoading"></Loader>
              <Row vertical-gutters v-if="!eventsEmpty">
                <Col
                  v-for="event in events"
                  :key="event.code"
                  :size="{ md: '12' }"
                >
                  <BroadcastCard
                    :title="event.name"
                    :disciplines="event.disciplines"
                    :location="event.location"
                    :weather="eventsWeather[event.code]"
                    :link="getBroadcastLink(event)"
                  ></BroadcastCard>
                </Col>
              </Row>
              <!-- Empty state : BEGIN -->
              <div class="container__mobile" v-else>
                <div class="block block_wide">
                  <EmptyState
                    :img="themeImages['finish-line']"
                    :title="$t('live.no_broadcasts_title')"
                    :subtitle="$t('live.no_broadcasts_subtitle')"
                  >
                  </EmptyState>
                </div>
              </div>
              <!-- Empty state : END -->
            </div>
            <!-- Broadcasts : END -->
            <!-- Banner : BEGIN -->
            <div class="section__group section__group_lg" v-show="false">
              Banner
            </div>
            <!-- Banner : END -->
            <div class="section__group section__group_lg">
              <div class="divider"></div>
            </div>
            <!-- Links : BEGIN -->
            <div class="section__group section__group_lg">
              <Row vertical-gutters>
                <Col :size="{ md: '12' }">
                  <FeatureCard
                    :img="require(`@/assets/img/feature-calendar.svg`)"
                    :title="$t('live.events_title')"
                    :href="theme !== 'pionercup' ? `${$env.RussiaRunning}/events` : 'https://pionercup.ru/'"
                  >
                    <div v-html="theme !== 'pionercup' ? $t('live.events_text') : $t('live.events_text_noNumber')"></div>
                  </FeatureCard>
                </Col>
                <Col :size="{ md: '12' }">
                  <FeatureCard
                    :img="require(`@/assets/img/feature-medal.svg`)"
                    :title="$t('live.results_title')"
                    :href="$env.Results"
                  >
                    <div v-html="$t('live.results_text')"></div>
                  </FeatureCard>
                </Col>
              </Row>
            </div>
            <!-- Links : END -->
          </Col>
        </Row>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header/HeaderExternal.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import FeatureCard from '@/components/FeatureCard/FeatureCard.vue';
import BroadcastCard from '../../components/BroadcastCard/BroadcastCard.vue';

export default {
  name: 'Index',
  components: {
    Header,
    EmptyState,
    FeatureCard,
    BroadcastCard,
  },
  props: {
    events: Object,
    eventsEmpty: Boolean,
    eventsLoading: Boolean,
  },
  computed: {
    ...mapGetters('broadcasts', ['eventsWeather']),
    ...mapGetters('settings', ['theme', 'themeImages']),
  },
  methods: {
    getBroadcastLink(event) {
      return `?code=${event.code}`;
    },
  },
};
</script>
