<template>
  <div
    class="search-results"
  >
    <div class="search-results__container">
      <!-- Field : BEGIN -->
      <slot name="field"></slot>
      <!-- Field : END -->
      <div
        class="search-results__block"
        v-show="isOpened"
      >
        <!-- Mobile field : BEGIN -->
        <div
          class="search-results__field d-md-none"
          :class="{'search-results__field_filled': enoughLength}"
        >
          <Button
            class="search-results__back"
            icon-left="left"
            shape="square"
            variant="clear"
            size="lg"
            mobile
            @click="$emit('close')"
          ></Button>
          <Input
            ref="mobileField"
            v-model.trim="internalValue"
            class="search-results__input"
            :placeholder="placeholder"
            type="search"
            no-border
            mobile
            clearable
          ></Input>
        </div>
        <!-- Mobile field : END -->
        <div class="search-results__content">
          <!-- Tips : BEGIN -->
          <div
            class="search-results__tip"
            v-if="$slots.tipPlaceholder"
            v-show="!isLoading && !enoughLength"
          >
            <slot name="tipPlaceholder"></slot>
          </div>
          <div
            class="search-results__tip"
            v-show="!isLoading && notFound"
          >
            <slot name="tipNotFound">
              <b>Ничего не&nbsp;найдено</b><br>
              <div class="text-sm mt-4">
                По вашему запросу ничего не&nbsp;найдено. Попробуйте поискать по&nbsp;другим ключевым словам.
              </div>
            </slot>
          </div>
          <div
            class="search-results__tip"
            v-show="isLoading"
          >
            <Loader class="m-0"></Loader>
          </div>
          <!-- Tips : END -->
          <!-- Results : BEGIN -->
          <div class="search-results__group pt-0 pb-0" v-show="!isLoading && enoughLength">
            <div class="search-results__items">
              <div
                v-for="(result, i) in results"
                :key="i"
                class="search-result"
                :class="result.classes"
                tabindex="0"
                @keydown.enter="select(result)"
                @click="select(result)"
              >
                <div class="search-result__content">
                  <slot name="resultLeftPart" :result="result">
                    <div class="search-result__title" v-html="result.title"></div>
                    <div class="search-result__subtitle text-sm" v-if="result.subtitle" v-html="result.subtitle"></div>
                  </slot>
                </div>
                <div class="search-result__icon search-result__icon_right">
                  <slot name="resultRightPart" :result="result">
                    <Icon name="right" size="m"></Icon>
                  </slot>
                </div>
              </div>
            </div>
          </div>
          <!-- Results : END -->
          <!-- Recent : BEGIN -->
          <div
            v-if="showRecentResults"
            v-show="recentResults.length && !isLoading && !enoughLength"
            class="search-results__group search-results__group_bordered-top"
          >
            <div class="search-results__group-title">
              <div class="group-title">{{ $t('you_recently_searched') }}</div>
            </div>
            <div class="search-results__items">
              <div
                v-for="(result, i) in recentResults"
                :key="i"
                class="search-result"
                tabindex="0"
                @keydown.enter="selectRecent(result)"
                @click="selectRecent(result)"
              >
                <div class="search-result__icon search-result__icon_left">
                  <Icon name="history" size="l"></Icon>
                </div>
                <div class="search-result__content">
                  <slot name="recentResultLeftPart" :result="result">
                    <div class="search-result__title" v-html="result.title"></div>
                  </slot>
                </div>
                <div class="search-result__icon search-result__icon_right">
                  <slot name="recentResultRightPart" :result="result">
                    <Icon name="query-arrow" size="m"></Icon>
                  </slot>
                </div>
              </div>
            </div>
          </div>
          <!-- Recent : END -->
        </div>
      </div>
    </div>
    <div
      class="search-results__overlay"
      v-show="isOpened"
      @click="$emit('close')"
    ></div>
  </div>
</template>

<script>
import { debounce } from '@/assets/js/helpers';

export default {
  name: 'SearchResults',
  i18n: {
    messages: {
      ru: {
        you_recently_searched: 'Вы недавно искали',
      },
      en: {
        you_recently_searched: 'You recently searched',
      },
    },
  },
  model: {
    prop: 'value',
  },
  props: {
    value: {
      default: null,
    },
    isOpened: Boolean,
    placeholder: String,
    minLength: {
      type: Number,
      default: 3,
    },
    showRecentResults: Boolean,
    recentResultsStorageKey: String,
  },
  data() {
    return {
      previousValue: null,
      results: [],
      recentResults: JSON.parse(localStorage.getItem(this.recentResultsStorageKey)) || [],
      isLoading: false,
      debouncedSearch: debounce(this.runSearch, 1000),
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    notFound() {
      return this.enoughLength && this.results.length === 0;
    },
    enoughLength() {
      return this.internalValue && this.internalValue.length >= this.minLength;
    },
  },
  methods: {
    reset() {
      this.internalValue = null;
      this.previousValue = null;
      this.results = [];
    },
    runSearch() {
      const val = this.internalValue || null;
      if (this.previousValue !== val && this.enoughLength) {
        this.$emit('search', val);
        return;
      }
      this.isLoading = false;
    },
    setResults(value, results) {
      this.results = results || [];
      this.previousValue = results ? value : null;
      this.isLoading = false;
    },
    select(result) {
      this.$emit('select', result);
    },
    /**
     * @param {Object} result
     * @param {string|number} result.id Identifier of result
     * @param {string|number} [result.title] Text to show in dropdown
     * @param {string|number} result.value Value to paste in field on result select
     * @param {Object} [result.data={}] Custom data
     */
    saveToRecent(result) {
      if (!result) {
        return;
      }
      const isExist = this.recentResults.some((r) => r.id === result.id);
      if (!isExist) {
        this.recentResults.unshift({
          data: result.data || {},
          ...result,
        });
        this.setRecentResults(this.recentResults.slice(0, 3));
      }
    },
    setRecentResults(results) {
      this.recentResults = results || [];
      this.saveRecentResultsInStorage();
    },
    saveRecentResultsInStorage() {
      localStorage.setItem(this.recentResultsStorageKey, JSON.stringify(this.recentResults));
    },
    selectRecent(result) {
      this.$emit('select:recent', result); // TODO: Add prevent default
      this.internalValue = result.value.toString();
    },
  },
  watch: {
    internalValue(val) {
      this.isLoading = this.enoughLength;
      this.$emit('change', val);
      this.debouncedSearch();
    },
    isOpened(val) {
      const isMobile = window.innerWidth < 768;
      if (!val && isMobile) {
        document.body.style.overflow = null;
      }
      if (val && isMobile) {
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
          this.$refs.mobileField.$el.querySelector('input').focus();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SearchResults";
</style>
