<template>
  <main class="layout__main">
    <Broadcast
      ref="broadcast"
      v-if="activeEventCode"
      :event="(isDirectLink ? allEvents[activeEventCode] : events[activeEventCode]) || {}"
      :events-loading="eventsLoading"
    ></Broadcast>
    <List
      v-else
      :events="events"
      :events-empty="eventsEmpty"
      :events-loading="eventsLoading"
    ></List>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '@/eventBus';
import List from './List.vue';
import Broadcast from './Broadcast.vue';

export default {
  name: 'Index',
  components: {
    List,
    Broadcast,
  },
  data() {
    return {
      eventsEmpty: false,
      eventsLoading: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeSettings']),
    ...mapGetters('broadcasts', ['events', 'allEvents']),
    isDirectLink() {
      return this.$route.meta.isDirectLink;
    },
    activeEventCode() {
      return this.isDirectLink
        ? this.$route.params.eventCode
        : this.$route.query.code;
    },
  },
  methods: {
    onEventsUpdate() {
      this.eventsEmpty = !Object.keys(this.events).length;
      this.eventsLoading = false;
      setTimeout(this.$refs.broadcast?.setData);
    },
    goToBroadcast(eventCode) {
      const broadcastRoute = this.$root.getBroadcastRoute(eventCode);

      this.$router.replace({
        params: broadcastRoute.params,
        query: broadcastRoute.query,
      }).catch(() => {});
    },
  },
  watch: {
    activeEventCode: {
      handler(val) {
        const eventCodeFromSettings = this.themeSettings.eventCode;

        if (eventCodeFromSettings && eventCodeFromSettings !== val) {
          this.goToBroadcast(eventCodeFromSettings);
        } else {
          window.scrollTo(0, 0);
        }
      },
      immediate: true,
    },
  },
  created() {
    eventBus.$on('eventsUpdated', this.onEventsUpdate);
    if (!Object.keys(this.events).length) {
      this.eventsLoading = true;
      this.$store.dispatch('broadcasts/updateEvents', this.isDirectLink);
    }
  },
  beforeDestroy() {
    eventBus.$off('eventsUpdated', this.onEventsUpdate);
  },
};
</script>
