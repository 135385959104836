var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"nav-transition","tag":"div","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpened),expression:"isOpened"}],staticClass:"rr-mobile-nav",class:{
      'rr-mobile-nav_dark': _vm.dark,
      'rr-mobile-nav_opened': _vm.isOpened,
    }},[_c('div',{staticClass:"rr-mobile-nav__overlay",on:{"click":_vm.close}}),_vm._v(" "),_c('div',{staticClass:"rr-mobile-nav__container"},[_c('div',{staticClass:"rr-mobile-nav__header"},[_vm._t("logo",function(){return [_c('div')]}),_vm._v(" "),_c('Button',{staticClass:"rr-mobile-nav__close",attrs:{"variant":"clear","shape":"square","icon-left":"close"},on:{"click":_vm.close}})],2),_vm._v(" "),_c('div',{staticClass:"rr-mobile-nav__body"},[_vm._l((_vm.nav),function(link,i){return [(link.divider)?_c('div',{key:i,staticClass:"rr-mobile-nav__divider"}):_c('NavigationItem',_vm._b({key:link.text},'NavigationItem',{
              ...link,
              icon: link.icon || 'caret-right',
              dark: _vm.dark,
            },false))]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }